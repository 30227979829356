/* Contact.css */
.contact-container {
    padding: 50px 0;
    background-color: #f3f3f3;
  }
  
  .contact-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    animation: fade-in 1s ease-out;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .contact-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .contact-description {
    font-size: 18px;
    margin-bottom: 40px;
    color: #666666;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .contact-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #004aad;
  }
  
  .contact-info-text {
    font-size: 16px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-form-input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form-message {
    height: 120px;
  }
  /* Contact.css */

.contact-form-button {
    padding: 10px 20px;
    background-color: #004aad;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
  }
  
  .contact-form-button:hover {
    background-color: #003b84;
  }
  
  @media (max-width: 768px) {
    .contact-title {
      font-size: 24px;
    }
  
    .contact-description {
      font-size: 16px;
    }
  
    .contact-icon {
      font-size: 20px;
    }
  
    .contact-info-text {
      font-size: 14px;
    }
  
    .contact-form-input {
      font-size: 14px;
    }
  }
  
  