.header {
    background-color: #007bff;
    color: white;
    padding: 20px;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
  }
  
  .nav-link {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #666;
  }
  