.about {
    background-color: #f8f8f8;
    padding: 50px 0;
  }
  
  .about-content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-description {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
  }
  
  .about-highlights {
    list-style-type: none;
    display: flex;
    justify-content: center;
  }
  
  .highlight {
    margin: 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
  