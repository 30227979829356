.carousel-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .carousel-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .carousel .slide {
    background: transparent;
    box-shadow: none;
  }
  
  .carousel .slide .legend {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .carousel .control-prev,
  .carousel .control-next {
    background: #fff;
    color: #000;
  }
  
  .carousel .control-arrow:hover {
    background: #000;
    color: #fff;
  }
  