/* OurWork.css */
.our-work-container {
    padding: 50px 0;
    background-color: #f3f3f3;
  }
  
  .our-work-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    animation: fade-in 1s ease-out;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .our-work-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .our-work-description {
    font-size: 18px;
    margin-bottom: 40px;
    color: #666666;
  }
  
  .our-work-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .our-work-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .our-work-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    transition: transform 0.3s ease-out;
  }
  
  .our-work-item:hover .our-work-image {
    transform: scale(1.1);
  }
  
  .our-work-overlay {
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  }  

  .our-work-item:hover .our-work-overlay {
    display: flex;
    opacity: 1;
  }
  .our-work-item-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .our-work-item-description {
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .our-work-title {
      font-size: 24px;
    }
  
    .our-work-description {
      font-size: 16px;
    }
  
    .our-work-item-title {
      font-size: 20px;
    }
  
    .our-work-item-description {
      font-size: 14px;
    }
  }
  