.facebook-page-feed {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fb-page {
    width: 100%;
    max-width: 300px; /* Adjust the max-width as desired */
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
  }
  
  
  
  .fb-page a {
    color: #385898;
    text-decoration: none;
    font-weight: bold;
  }
  
  .fb-page a:hover {
    text-decoration: underline;
  }
  
  .fb-page blockquote {
    margin: 10px 0;
  }
  
  .fb-page blockquote:before {
    content: "\\201C";
    font-size: 24px;
    margin-right: 5px;
  }
  
  .fb-page blockquote:after {
    content: "\\201D";
    font-size: 24px;
    margin-left: 5px;
  }
  
  .fb-page blockquote p {
    margin: 0;
    line-height: 1.4;
  }
  